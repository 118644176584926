import { Typography } from "@material-ui/core"
import BouncingImage from "common/BouncingImage"
import { GlobalContext } from "common/GlobalContext"
import LinksWrap from "common/LinksWrap"
import { useIsDesktop } from "common/MediaQueries"
import { SectionProps } from "common/SectionProps"
import { useContext } from "react"

import styled from "styled-components"
import {
  bouncingWrapper,
  offScreen,
  SectionContainer,
  transition,
} from "theme/shared"

const Styled = {
  SectionContainer: styled(SectionContainer)`
    padding: 200px 25px;
    justify-content: space-between;
    position: relative;

    ${(p) => p.theme.breakpoints.up("md")} {
      margin-left: 40%;
      padding-top: 50vh;
      height: max-content;
      width: auto;
    }
    li {
      ${transition}
      ${(p) =>
        p.active &&
        `
        transform:rotate3d(1,0,0,0); !important;
        visibility:visible !important;
        opacity:1 !important;
    `}
    }
  `,
  Headline: styled(Typography).attrs({ variant: "h3", component: "h2" })`
    ${offScreen({ xPos: "calc(-100% - 80vh)" })}
    color:${(p) => p.theme.colors.darkRoyalBlue};
    span {
      font-weight: 500;
    }
  `,
  Paragraph: styled(Typography)`
    color: ${(p) => p.theme.colors.darkRoyalBlue};
    width: 330px;
    font-weight: 500;
    ${offScreen({ yPos: "-50%" })};
  `,
  Floating01: styled(bouncingWrapper)`
    --top: 120px;
    left: 32px;
    top: var(--top);
    ${offScreen({ yPos: `calc( -100% - var(--top) )` })}
    ${(p) => p.theme.breakpoints.up("md")} {
      left: 100px;
      z-index: 5;
      --top: 132px;
    }
  `,
  Floating02: styled(bouncingWrapper)`
    --top: 120px;
    left: -32px;
    top: var(--top);
    ${offScreen({ yPos: "calc( -100% - var(--top) )" })};
    ${(p) => p.theme.breakpoints.up("md")} {
      --top: 121px;
    }
  `,
  Floating03: styled(bouncingWrapper)`
    --top: 328px;
    left: 0;
    top: var(--top);
    ${offScreen({ yPos: "calc( -100% - var(--top) )" })}

    ${(p) => p.theme.breakpoints.up("md")} {
      --top: 600px;
    }
  `,
  Floating04: styled(bouncingWrapper)`
    --top: 130px;
    right: -12px;
    top: var(--top);
    ${offScreen({ yPos: "calc( -100% - var(--top) )" })}
    ${(p) => p.theme.breakpoints.up("md")} {
      --top: 234px;
    }
  `,
  Floating05: styled(bouncingWrapper)`
    --top: 410px;
    right: -12px;
    top: var(--top);
    ${offScreen({ yPos: "calc( -100% - var(--top) )" })}
    ${(p) => p.theme.breakpoints.up("md")} {
      --top: 454px;
    }
  `,
}

const SectionContact = ({ position }: SectionProps) => {
  const { active, openMenu, prev, next } = useContext(GlobalContext)
  const isDesktop = useIsDesktop()

  if (active !== position && prev !== position && next !== position) {
    return null
  }

  return (
    <Styled.SectionContainer active={active === position} openMenu={openMenu}>
      <Styled.Headline>Nice to meet you!</Styled.Headline>
      <Styled.Paragraph>
        Let’s work together to create game-changing experiences that will take
        your brand to the next level
      </Styled.Paragraph>

      <LinksWrap />

      <Styled.Floating01>
        <BouncingImage
          deg={30}
          image={"./contact/contact-left.svg"}
          width={isDesktop ? "121px" : "42px"}
          height={isDesktop ? "124px" : "43px"}
        />
      </Styled.Floating01>
      <Styled.Floating02>
        <BouncingImage
          deg={180}
          image={"./blue_circle.svg"}
          width={isDesktop ? "224px" : "60px"}
          height={isDesktop ? "224px" : "60px"}
        />
      </Styled.Floating02>
      <Styled.Floating03>
        <BouncingImage
          deg={0}
          image={"./contact/contact-left3.svg"}
          width={isDesktop ? "134px" : "88.7px"}
          height={isDesktop ? "124px" : "82.1px"}
        />
      </Styled.Floating03>
      <Styled.Floating04>
        <BouncingImage
          deg={180}
          image={"./contact/contact-right.svg"}
          width={isDesktop ? "132px" : "60px"}
          height={isDesktop ? "129px" : "70px"}
        />
      </Styled.Floating04>
      <Styled.Floating05>
        <BouncingImage
          deg={150}
          image={"./contact/contact-right2.svg"}
          width={isDesktop ? "114px" : "55px"}
          height={isDesktop ? "111px" : "67px"}
        />
      </Styled.Floating05>
    </Styled.SectionContainer>
  )
}

export default SectionContact
