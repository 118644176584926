export type Employee = {
  name: string
  image: string
  role?: string
}

export const employees: Employee[] = [
  {
    name: "Hadas Grossman Ella",
    image: "/employees/hadass",
    role: "CEO",
  },
  {
    name: "Moran Lupo",
    image: "/employees/moran",
    role: "COO & CPO",
  },
  {
    name: "Sarah Elimeleh",
    image: "/employees/sara",
    role: "VP Product innovation & strategy",
  },
  {
    name: "Noam Amiran",
    image: "/employees/noam",
    role: "Co-CTO",
  },
  {
    name: "Lior Shabo",
    image: "/employees/lior",
    role: "Co-CTO",
  },
  {
    name: "Gil Bliman",
    image: "/employees/gil",
    role: "Co-CTO",
  },
  {
    name: "Alex Fost",
    image: "/employees/alex",
    role: "Product Director",
  },
  {
    name: "Eli Brikman",
    image: "/employees/eli",
    role: "VP Delivery",
  },
  {
    name: "Omri Peled",
    image: "/employees/omri",
    role: "VP Business Development & Growth",
  },
  {
    name: "Ayana Kovalsky",
    image: "/employees/ayana",
    role: "Head of UX/UI",
  },
  {
    name: "Roy Levy",
    image: "/employees/roy",
    role: "Head of QA",
  },
  {
    name: "Audrey Ben Baruch",
    image: "/employees/audrey",
    role: "Office Manager",
  },
]
